import shopifyGenericDefaults from '../shopify-generic/config.js';

const AutoSyncKey = 'blackpatchperformanc'; // AutoSync key is limited to 20 chars, so we had to trim store id

const { baseFields, extraFields } = shopifyGenericDefaults.fitmentSearch;

const wheelsFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

shopifyGenericDefaults.Widgets.find((w) => w.name === 'Garage').location = {
  selector: '#cm-garage',
  class: 'cm_garage__desktop cm_mobile-hide',
};
shopifyGenericDefaults.Widgets.find((w) => w.name === 'MobileGarage').location = {
  selector: '#cm-mobile-garage',
  class: 'cm_garage__mobile cm_desktop-hide',
};
shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBrandTiles').location = '#cm_brand-list';

const { ignoreFields } = shopifyGenericDefaults.Widgets.find((w) => w.name === 'Facets');
ignoreFields.push('tire_system', 'tire_height');

globalThis.Convermax.discardVehicleAndTireSizes = () => {
  window.Convermax.discardVehicle();
  window.document
    .querySelector('.cm_FacetBarTires .cm_vehicle-widget_button__clear:not([disabled])')
    ?.click();
};

export default {
  includes: ['shopify-generic'],
  storeId: 'blackpatchperformance',
  ...shopifyGenericDefaults,
  SearchRequestDefaults: {
    ...shopifyGenericDefaults.SearchRequestDefaults,
    extra: {
      ...shopifyGenericDefaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === '/',
    },
  },
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    isVehicleSelectionIsolated: true,
    categorySelectionPages: [
      ...shopifyGenericDefaults.fitmentSearch.categorySelectionPages,
      {
        pathname: window.document.querySelector('#cm_brand-list') ? window.location.pathname : null,
        field: 'vendor',
      },
    ],
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets,
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-home-ymm',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 650,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: {
        selector: '#cm-home-wheels',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/collections/wheels',
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: {
        selector: '#cm-home-tire',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/collections/tires',
      selectFields: tiresFields,
    },
    {
      name: 'WheelTireVehicleInfo',
      autoSyncVisualization: {
        apiKey: AutoSyncKey,
        config: {
          height: '100px',
        },
      },
    },
    {
      name: 'FitmentTableMobile',
      type: 'FitmentTable',
      location: '#cm-fitment-table-mobile',
      template: 'fitmentSearch/fitmentTable',
      fields: [
        ...baseFields,
        ...extraFields,
        ...(globalThis.Convermax.config?.customFitmentTableFields || []),
      ],
    },
    {
      name: 'AutoSyncVisualizationDialog',
      apiKey: AutoSyncKey,
      template: 'fitmentSearch/autoSyncVisualizationDialog',
    },
  ],
};
